@media only screen and (max-width: 600px) {
  .status-container {
    flex-direction: column;
  }
  .button {
    flex-direction: row;
    justify-content: space-between;
  }
  .button button {
    margin-bottom: 5px;
  }
  /* .contact-info {
    margin-top: 10px;
  } */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  div {
    text-align: center; /* Center the content on smaller screens */
  }

  /* div > div {
    text-align: left;
  } */
}
