/* Default styles for larger screens */
.complain-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem; /* Adjust the gap between images */
  margin-top: 20px;
}

.complain-image {
  width: 90px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}
.complai-image {
  width: 55px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .complain-container {
    flex-direction: column; /* Stack images vertically on smaller screens */
    text-align: center; /* Center images */
  }

  .complain-image {
    width: 60%; /* Make images take full width on smaller screens */
    margin-bottom: 20px; /* Add margin between images */
  }
  .complai-image {
    width: 40%; /* Make images take full width on smaller screens */
    margin-bottom: 20px; /* Add margin between images */
  }
}
@media (max-width: 600px) {
  .complain-container {
    flex-direction: column; /* Stack images vertically on smaller screens */
    text-align: center; /* Center images */
  }

  .complain-image {
    width: 60%; /* Make images take full width on smaller screens */
    margin-bottom: 20px; /* Add margin between images */
    margin-left: 45px;
  }
  .complai-image {
    width: 40%; /* Make images take full width on smaller screens */
    margin-bottom: 20px; /* Add margin between images */
  }
}
