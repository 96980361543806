/* Contact.css */

.contact-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.contact-text {
  max-width: 100%;
  margin-bottom: 20px;
}

.contact-form input {
  width: 100%;
}

.contact-image {
  max-width: 100%;
  margin-top: 20px;
  text-align: center;
}

/* Adjustments for smaller screens */
@media only screen and (min-width: 768px) {
  .contact-container {
    flex-direction: row;
  }

  .contact-text {
    flex: 1;
    margin-right: 20px;
  }

  .contact-form input {
    width: calc(100% - 20px); /* Adjust input width for margin */
  }

  .contact-image {
    flex: 1;
    margin-top: 0;
    text-align: left; /* Align the content to the left on larger screens */
  }
}
