/* test.css */

/* Add your existing styles here */

/* Responsive styles for smaller screens */
@media only screen and (max-width: 768px) {
  .card {
    width: 100%; /* Adjust width for smaller screens */
  }
  img {
    width: 80%;
  }
  div {
    text-align: center; /* Center the content on smaller screens */
  }

  /* div > div {
    text-align: center;
  } */
}

/* Add more media queries as needed for other screen sizes */
/* Add this to your CSS file */
@media (max-width: 600px) {
  .card {
    height: auto;
  }
  img {
    width: 60%;
  }

  .responsive {
    max-height: 100px;
  }

  .card p {
    font-size: 14px;
    margin: 5px 0;
  }
}
